<template>
  <div class="body">
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
      absolute
      right
      top
      color="green"
      elevation="6"
      multi-line
      tile
      class="snackbar"
      transition="slide-x-reverse-transition"
    >
      Профиль успешно изменен
    </v-snackbar>
    <v-card tile class="mt-5 mb-5">
      <div class="profile-form">
        <v-card-title>
          <v-row>
            <v-col cols="9"> Настройка профиля</v-col>
            <v-col cols="3 text-right ">
              <v-btn
                class="mx-2"
                fab
                color="success"
                @click="disabled = !disabled"
              >
                <v-icon dark> mdi-pencil </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-form @submit.prevent.stop="Submit">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="4" lg="3" xl="3">
              <v-card-text>
                <v-img
                  max-width="100%"
                  :lazy-src="current_image"
                  :src="current_image"
                ></v-img>
                <div v-if="!disabled" class="fileUpload">
                  <input @change="OnFileChange" type="file" class="upload" />
                  <span>ИЗМЕНИТЬ ИЗОБРАЖЕНИЕ</span>
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="8" lg="9" xl="9">
              <v-card-text>
                <v-text-field
                  :error-messages="first_nameErrors"
                  @input="$v.form.first_name.$touch()"
                  @blur="$v.form.first_name.$touch()"
                  color="success"
                  label="Имя"
                  v-model="form.first_name"
                  :readonly="disabled"
                ></v-text-field>

                <v-text-field
                  :error-messages="last_nameErrors"
                  color="success"
                  label="Фамилия"
                  v-model="form.last_name"
                  :readonly="disabled"
                ></v-text-field>

                <v-text-field
                  :error-messages="emailErrors"
                  color="success"
                  label="Email"
                  v-model="form.email"
                  :readonly="disabled"
                ></v-text-field>

                <v-text-field
                  :counter="13"
                  :error-messages="phoneErrors"
                  label="Телефонный номер"
                  required
                  v-model="form.phone"
                  :readonly="disabled"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn
                  color="success"
                  class="password"
                  @click="dialog = true"
                  :disabled="disabled"
                  right
                  >Изменить пароль</v-btn
                >
              </v-card-text>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="success"
              :disabled="disabled"
              type="submit"
              block
              depressed
            >
              Сохранить
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </div>
    </v-card>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          Изменить пароль
          <v-spacer></v-spacer>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-form @submit.stop.prevent="PasswordChange">
          <v-card-text>
            <v-text-field
              :error-messages="CurrentPasswordErrors"
              color="success"
              label="Текущий пароль"
              v-model="password_form.current"
              :readonly="disabled"
            ></v-text-field>
            <span v-if="current_errors" class="text-caption error--text">{{
              current_errors
            }}</span>
            <v-text-field
              :error-messages="NewPasswordErrors"
              type="password"
              color="success"
              label="Новый пароль"
              v-model="password_form.new"
              :readonly="disabled"
            ></v-text-field>
            <v-text-field
              :error-messages="NewPasswordConfirmErrors"
              color="success"
              type="password"
              label="Подтвердите новый пароль"
              v-model="password_form.new_confirm"
              :readonly="disabled"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              @click="PasswordChange"
              text
              color="blue darken-1"
              >сохранить</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { USER_PUT, USER_PASSWORD_CHANGE } from "@/store/actions/users";

export default {
  data() {
    return {
      loadingEdit: false,
      disabled: true,
      snackbar: false,
      dialog: false,
      current_image: null,
      form: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        image: null,
        user_access_level: "",
      },
      errors: null,
      api: process.env.VUE_APP_API_URL.slice(0, -1),
      password_form: {
        current: null,
        new: null,
        new_confirm: null,
      },
      current_errors: null,
    };
  },
  mounted() {
    this.LoadData();
  },
  methods: {
    async LoadData() {
      (this.form.first_name = this.$store.getters.profile.first_name),
        (this.form.last_name = this.$store.getters.profile.last_name),
        (this.form.email = this.$store.getters.profile.email),
        (this.form.phone = this.$store.getters.profile.phone),
        (this.form.user_access_level =
          this.$store.getters.profile.user_access_level),
        (this.current_image = this.api + this.$store.getters.profile.image);

      let form = new FormData();
      form.append("image", this.form.image);
      this.form.image = form.image;
    },

    async Submit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.errors = "ERROR";
        return false;
      } else {
        let resp = await this.$dispatch(USER_PUT, this.form);
        if (resp.success) {
          this.$store.commit("profile", resp.result);
          this.disabled = true;
          this.snackbar = true;
        } else {
          alert(resp.result);
        }
      }
    },
    async PasswordChange() {
      this.$v.password_form.$touch();
      if (this.$v.password_form.$invalid) {
        this.errors = "ERROR";
        return false;
      } else {
        let resp = await this.$dispatch(
          USER_PASSWORD_CHANGE,
          this.password_form
        );
        if (resp.success) {
          this.disabled = true;
          this.password_form.current = null;
          this.password_form.new = null;
          this.password_form.new_confirm = null;
          this.dialog = false;
          this.snackbar = true;
        } else if (resp.fail) {
          this.current_errors = "Текущий пароль неверен.";
        } else {
          alert(resp.result);
        }
      }
    },
    OnFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
      this.form.image = files[0];
      this.current_image = URL.createObjectURL(files[0]);
    },
    createImage(file) {
      var image = new Image();
      var reader = new FileReader();

      reader.onload = (e) => {
        this.current_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
  },

  computed: {
    first_nameErrors() {
      const errors = [];
      if (!this.$v.form.first_name.$dirty) return errors;
      !this.$v.form.first_name.required && errors.push("Имя обязательно");
      return errors;
    },
    last_nameErrors() {
      const errors = [];
      if (!this.$v.form.last_name.$dirty) return errors;
      !this.$v.form.last_name.required && errors.push("Фамилия обязательна");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.form.phone.$dirty) return errors;
      !this.$v.form.phone.required && errors.push("Номер телефона обязателен");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.required &&
        errors.push("Электронная почта обязательна");
      return errors;
    },
    CurrentPasswordErrors() {
      const errors = [];
      if (!this.$v.password_form.current.$dirty) return errors;
      !this.$v.password_form.current.required &&
        errors.push("Требуется текущий пароль.");
      return errors;
    },
    NewPasswordErrors() {
      const errors = [];
      if (!this.$v.password_form.new.$dirty) return errors;
      !this.$v.password_form.new.min &&
        errors.push("Новый пароль должен содержать не менее 6 символов.");
      !this.$v.password_form.new.required &&
        errors.push("Требуется новый пароль.");
      return errors;
    },
    NewPasswordConfirmErrors() {
      const errors = [];
      if (!this.$v.password_form.new_confirm.$dirty) return errors;
      !this.$v.password_form.new_confirm.sameAsPassword &&
        errors.push("Подтверждение нового пароля не совпадает.");
      !this.$v.password_form.new_confirm.required &&
        errors.push("Требуется подтверждение нового пароля.");
      return errors;
    },
  },

  validations() {
    return {
      password_form: {
        current: { required },
        new: { required, min: minLength(6) },
        new_confirm: {
          required,
          sameAsPassword: sameAs("new"),
        },
      },
      form: {
        first_name: {
          required,
        },
        last_name: {
          required,
        },
        email: {
          required,
        },
        phone: {
          required,
        },
      },
    };
  },
};
</script>



<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;500&display=swap");
$body-font-family: "Roboto";

.password {
  float: right !important;
  margin-top: 10px !important;
}

.profile-form {
  max-width: 900px;
  box-sizing: border-box;
  margin: auto;
  margin-top: 10px;
}
.fileUpload {
  background: #2c6fb2;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  margin: 1.25em auto; /*20px/16px 0*/
  overflow: hidden;
  padding: 0.5em; /*14px/16px*/
  position: relative;
  text-align: center;
  width: 100%;
  cursor: pointer;
  box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.46);
  -webkit-box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.46);
}
.fileUpload:hover,
.fileUpload:active,
.fileUpload:focus {
  background: #3575b6;
  cursor: pointer;
}
.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 100%;
  height: 46px;
  cursor: pointer;
}
.fileUpload > span {
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  font-size: 0.875rem;
  font-family: $body-font-family;
}

input[type="file"] {
  position: fixed;
  right: 100%;
  bottom: 100%;
}
</style>